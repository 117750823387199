import React, { useEffect, useState } from "react";

// Images
import Logo from "../assets/img/Logo.svg";

// Components
import Footer from "../components/footer/Footer";
import Loading from "../components/loading/Loading";

export default function Home() {
  const loadingValues = [60, 100];

  const [loading, setIsloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1730);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="container-technic">
            <div className="technical-error">
              <a href="https://hub.profyer.com/">
                <img src={Logo} alt="logo profyer.com" />
              </a>
              <div className="message-modal">
                <h1>THE ONLY PLACE FOR TRUE PROFESSIONALS NEARLY READY</h1>
                <div className="loading__wrapper">
                  <progress
                    id="file"
                    value={loadingValues[0]}
                    max={loadingValues[1]}
                  ></progress>
                  <div className="values">
                    <p>{loadingValues[0]}%</p>
                    <p>{loadingValues[1]}%</p>
                  </div>
                </div>
                <a href="https://hub.profyer.com/">
                  <button type="button" className="button">
                    Notify me!
                  </button>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
