import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="social-links">
        <h4>Our Facebook groups:</h4>
        <ul>
          <li><a href='https://www.facebook.com/groups/590792223061904' target='_blank' rel="noreferrer">Profyer Netherlands</a></li>
          <li><a href='https://www.facebook.com/groups/1363441254494742' target='_blank' rel="noreferrer">Profyer Spain</a></li>
          <li><a href='https://www.facebook.com/groups/1209389173341726' target='_blank' rel="noreferrer">Profyer Lithuania</a></li>
        </ul>
      </div>
    </div>
  );
}
