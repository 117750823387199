import React from "react";
import { BrowserRouter } from "react-router-dom";

// Router
import Router from "./router/Router";

// Style
import './App.scss';

function App() {

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
