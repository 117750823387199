import React from 'react'

// Images
import Logo from '../../assets/img/Logo.svg'

export default function Loading() {
  return (
    <div className='Loader'>
      <div className="container">
        <div className='loader-body'>
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </div>
  )
}
